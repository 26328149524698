import { FieldErrors, FieldError } from 'react-hook-form';

// NB: For some reason, not splitting the name into each piece always returns undefined for checklist responses.
export const useError = (name: string, errors: FieldErrors) => {
	const namePieces = name.split('.');

	let errorObject: FieldErrors | FieldError | undefined = errors;
	namePieces.forEach((namePiece) => {
		if (errorObject?.[namePiece]) {
			errorObject = errorObject[namePiece];
		} else {
			errorObject = undefined;
		}
	});

	return errorObject;
};
