import { Listbox, Transition } from '@headlessui/react';
import { titlifyEnum } from '@utils/stringHelpers';
import clsx from 'clsx';
import { Fragment } from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import { ErrorMessage } from '~/components/form-fields/ErrorMessage';
import { defaultClassName, Label } from '~/components/form-fields/Label';
import { FAIcon } from '../utils/FAIcons';
import { FormInputProps } from './FormProps';
import { isNotNullish } from '@utils/types';
import { FAIconProps } from '../utils/FAIcons/FAIconsProps';

export type SelectInputValue = object | string | number;

export interface Option<TValue extends SelectInputValue> {
	disabled?: boolean;
	imgUrl?: string;
	icon?: string;
	iconColor?: string;
	iconPrefix?: FAIconProps['prefix'];
	label: string | JSX.Element;
	subLabel?: string;
	value: TValue;
}

export function createSimpleOptions<TValue extends string>(items: TValue[], titlify = true): Option<TValue>[] {
	return items.map((opt) => ({
		label: titlify ? titlifyEnum(opt) : opt,
		value: opt,
	}));
}

type MaybeControlled<TValue> =
	| {
			defaultValue: TValue;
			value?: undefined;
	  }
	| {
			defaultValue?: undefined;
			value: TValue;
	  };

type CanBeNull<TValue> = {
	canBeNull: true;
	onChange?: (newValue: TValue | null) => void;
};

type CannotBeNull<TValue> = {
	canBeNull?: false;
	onChange?: (newValue: TValue) => void;
};

type MaybeNullableAndControlled<TValue> = (CanBeNull<TValue> & MaybeControlled<TValue | null>) | (CannotBeNull<TValue> & MaybeControlled<TValue>);

type SharedSelectInputProps<TValue extends SelectInputValue> = Pick<
	FormInputProps,
	| 'borderColor'
	| 'className'
	| 'disabled'
	| 'errorMessage'
	| 'form'
	| 'icon'
	| 'id'
	| 'labelClassName'
	| 'labelText'
	| 'mobile'
	| 'placeholder'
	| 'rightIcon'
	| 'subLabel'
	| 'testId'
> & {
	by?: keyof TValue & string;
	direction?: 'up' | 'down';
	isFullWidth?: boolean;
	isLoading?: boolean;
	options: Option<TValue>[];
	optionsClassName?: string;
};

type SelectInputProps<TValue extends SelectInputValue> = SharedSelectInputProps<TValue> & MaybeNullableAndControlled<TValue>;

type ListboxOptionProps<TValue extends SelectInputValue> = {
	mobile: boolean | undefined;
} & (
	| {
			option: Option<TValue>;
			placeholderText?: undefined;
	  }
	| {
			option?: undefined;
			placeholderText: string;
	  }
);

const avatarClassName = 'flex-shrink-0 h-6 w-6 rounded-full mr-2';

function ListboxOption<TValue extends SelectInputValue>({ mobile, option, placeholderText }: ListboxOptionProps<TValue>) {
	const disabled = option?.disabled;
	const imgUrl = option?.imgUrl;
	const icon = option?.icon;
	const iconColor = option?.iconColor;
	const iconPrefix = option?.iconPrefix ?? 'fas';
	const label = option?.label ?? placeholderText;
	const subLabel = option?.subLabel;
	const value = option?.value ?? null;
	return (
		<Listbox.Option
			className={({ active }) =>
				`relative select-none ${mobile ? 'text-sm py-2 px-3 pr-1' : 'py-2 pl-10 pr-4 sm:pl-3 sm:pr-1'} ${
					active && !mobile ? 'bg-gray-200 text-black' : active && mobile ? 'bg-glazier-blue-100' : 'text-gray-900'
				} ${disabled ? 'bg-gray-100 text-gray-600 cursor-not-allowed' : 'cursor-pointer'}`
			}
			disabled={disabled}
			value={value}
		>
			{({ selected }) => (
				<>
					<div className="flex items-center justify-between">
						{imgUrl && <img src={imgUrl} className={avatarClassName} />}
						{!!icon && <FAIcon prefix={iconPrefix} icon={icon} style={{ color: iconColor }} />}
						<div className={`flex flex-col truncate ${selected ? 'font-medium' : 'font-normal'}`}>
							<div>{label}</div>
							{subLabel && <div className="text-gray-500">{subLabel}</div>}
						</div>
						<span className="pl-1 text-green-600 xs:hidden">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								{selected && <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />}
							</svg>
						</span>
					</div>
				</>
			)}
		</Listbox.Option>
	);
}

export const BetterSelectInput = <TValue extends SelectInputValue>({
	borderColor = 'border-gray-400',
	by,
	canBeNull,
	className,
	defaultValue,
	direction,
	disabled,
	errorMessage,
	form,
	icon,
	id,
	isFullWidth = false,
	isLoading,
	labelClassName,
	labelText,
	mobile,
	options,
	optionsClassName,
	onChange,
	placeholder,
	rightIcon,
	subLabel,
	testId,
	value,
}: SelectInputProps<TValue>) => {
	const directionClass = direction === 'up' ? 'bottom-full max-h-40' : 'top-full';
	const placeholderText = placeholder ?? 'Click here to select';
	return (
		<div>
			{labelText && <Label className={clsx('mb-1', labelClassName ?? defaultClassName)} id={id} labelText={labelText} />}
			{subLabel && <p className={clsx('text-sm text-gray-700 mb-1', labelClassName ?? defaultClassName)}>{subLabel}</p>}
			<Listbox form={form} onChange={onChange} disabled={disabled || isLoading} {...{ by, value, defaultValue }}>
				<div id={id} className={`relative ${className ?? ''}`}>
					<Listbox.Button
						data-testid={testId}
						className={clsx(
							`relative cursor-default rounded-lg bg-${disabled ? 'gray-100 hover:cursor-not-allowed' : 'white hover:cursor-pointer'}
            ${mobile ? 'pl-2 pr-6 text-sm' : 'pl-3 pr-10'}
            text-left py-1.5 border ${borderColor} focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300`,
							{
								'pr-4': icon || rightIcon,
								'w-full': isFullWidth,
							}
						)}
					>
						{({ value }) => {
							const selectedOption = options.find(
								by !== undefined ? (opt) => isNotNullish(value) && opt.value[by] === value[by] : (opt) => opt.value === value
							);
							return (
								<div className="flex items-center">
									{icon}
									{selectedOption?.imgUrl && <img src={selectedOption.imgUrl} className={avatarClassName} />}
									{!!selectedOption?.icon && (
										<FAIcon
											prefix={selectedOption.iconPrefix ?? 'fas'}
											icon={selectedOption.icon}
											className="mr-2"
											style={{ color: selectedOption.iconColor }}
										/>
									)}
									<span className="block truncate">{isLoading ? 'Loading...' : (selectedOption?.label ?? placeholderText)}</span>
									{rightIcon}
									<span
										className={`pointer-events-none absolute inset-y-0 right-0 flex items-center ${
											mobile ? 'text-sm pr-1' : 'pr-2'
										}`}
									>
										{/* NB: can be changed to FA icon angles-up-down after updating library version */}
										{!icon && !rightIcon && (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												className={mobile ? 'h-4 w-4' : 'h-6 w-6'}
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
												strokeWidth={2}
											>
												<path strokeLinecap="round" strokeLinejoin="round" d="M8 9l4-4 4 4m0 6l-4 4-4-4" />
											</svg>
										)}
									</span>
								</div>
							);
						}}
					</Listbox.Button>
					<Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
						<Listbox.Options
							className={clsx(
								`absolute z-[100] mt-1 max-h-60 overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${directionClass}`,
								{
									'w-full': isFullWidth,
								},
								optionsClassName
							)}
						>
							{canBeNull && <ListboxOption mobile={mobile} placeholderText={placeholderText} />}
							{options?.map((opt, optIdx) => <ListboxOption key={optIdx} mobile={mobile} option={opt} />)}
						</Listbox.Options>
					</Transition>
				</div>
			</Listbox>
			{errorMessage && <ErrorMessage errorMessage={errorMessage} />}
		</div>
	);
};

type ControlledSelectInputProps<TFieldValues extends FieldValues, TValue extends SelectInputValue> = SharedSelectInputProps<TValue> &
	(CanBeNull<TValue> | CannotBeNull<TValue>) & {
		control: Control<TFieldValues>;
		name: Path<TFieldValues>;
	};

export const ControlledBetterSelectInput = <TFieldValues extends FieldValues, TValue extends SelectInputValue>(
	props: ControlledSelectInputProps<TFieldValues, TValue>
) => {
	const { canBeNull, control, name, onChange, ...rest } = props;
	return (
		<Controller
			control={control}
			name={name}
			render={({ field: { value, onChange: controllerOnChange } }) => {
				return canBeNull ? (
					<BetterSelectInput<TValue>
						canBeNull
						{...rest}
						onChange={(value) => {
							controllerOnChange(value);
							onChange?.(value);
						}}
						value={value}
					/>
				) : (
					<BetterSelectInput<TValue>
						{...rest}
						onChange={(value) => {
							controllerOnChange(value);
							onChange?.(value);
						}}
						value={value}
					/>
				);
			}}
		/>
	);
};
